
import Vue from "vue";

export default Vue.extend({
  props: {
    isVisibleFooter: {
      type: Boolean,
      required: true,
    },
    linkedNovelCount: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleFooterAction(): void {
      this.$emit("footer-action");
    },
  },
});
