var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isVisibleFooter,
            expression: "isVisibleFooter",
          },
        ],
        staticClass: "fade-footer fade-element",
        on: { click: _vm.handleFooterAction },
      },
      [
        _c("div", { staticClass: "left-text" }, [
          _c("span", [_vm._v("累計 ")]),
          _c("span", { staticClass: "highlight" }, [
            _c("span", { staticClass: "font-large" }, [
              _vm._v(_vm._s(_vm.linkedNovelCount)),
            ]),
            _c("span", [_vm._v("作品")]),
          ]),
          _c("span", [_vm._v(" を企業提案中！")]),
        ]),
        _c("div", { staticClass: "right-button" }, [
          _vm._v("機能を使ってみる ＞"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }